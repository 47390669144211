import {
  InfoSectionEvent,
  IProductDTO,
  IProductPageControllerConfig,
  IProductPageStyleParams,
  IPropsInjectedByViewerScript,
  ProductPagePagination,
  ProductPagePaginationItem,
  ProdudctQunatityRange,
  SocialSharingEvent,
  TranslationDictionary,
  UserInput,
  UserInputData,
  UserInputErrors,
  IFedopsCustomParamsFull,
  BILoggerEvents,
  IMediaItem,
} from '../types/app-types';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {
  ImageModeType,
  ModalState,
  MULTILINGUAL_TO_TRANSLATIONS_MAP,
  productPageFedopsEvent,
  trackEventMetaData,
  translationPath,
  UserInputType,
  LayoutNames,
  Layout,
  ProductType,
  ErrorTooltipPlacement,
  LayoutId,
  COMPONENTS_ADD_TO_CART_TEXT_KEYS,
  Origin,
  BiExposureTestName,
  PRODUCT_PAGE_APP_NAME,
  QUICK_VIEW_APP_NAME,
  COMPONENTS_OUT_OF_STOCK_TEXT_KEYS,
} from '../constants';
import {ProductService} from '../services/ProductService';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {
  formatCustomTextFields,
  userInputsFactory,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import * as _ from 'lodash';
import {
  ActionStatus,
  AddToCartActionOption,
  APP_DEFINITION_ID,
  BiButtonActionType,
  PageMap,
  PubSubEvents,
  STORAGE_PAGINATION_KEY,
  StoresWidgetID,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {all, capitalizeFirstLetters} from '../commons/utils';
import {MultilingualService} from '@wix/wixstores-client-core/dist/src/multilingualService/multilingualService';
import {IAppSettings, IProductOptionSelection} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import {IControllerConfig, StructurePage} from '@wix/native-components-infra/dist/src/types/types';
import {parseUrl} from '@wix/native-components-infra/dist/src/urlUtils';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/src/types/site-map';
import {ITrackEventParams} from '@wix/native-components-infra/dist/es/src/types/wix-sdk';
import {clickOnProductDetailsSfParams, exposureEventForTestsParams, socialButtonsParams} from '@wix/bi-logger-ec-sf';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {updateWixCounters} from '../services/countersApi';
import {
  ProductPageItemData,
  SeoProductBuilder,
} from '@wix/wixstores-client-core/dist/es/src/builders/SeoItemData.builder';
import {queryToString} from '../services/urlUtils';
import {SPECS} from '../specs';
import {WishlistActions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/wishlist-actions/WishlistActions';
import {DirectPurchaseService} from '../services/DirectPurchaseService';
import {TrackEventName} from '@wix/wixstores-client-core/dist/es/src/types/track-event';
import {SubscriptionService} from '../services/SubscriptionService';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {DeepPartial} from 'tsdef';
import {ResultProp, withChangeListener} from '../providers/withChangeListener';
import {GetProductBySlugQuery} from '../graphql/queries-schema';
import {ShippingContactRestricted} from '@wix/cashier-express-checkout-widget/dist/testkit/fixtures/ShippingContactBuilder';
import {cashierExpressAddressToEcomAddress} from '@wix/wixstores-client-storefront-sdk/dist/src/cart/cashierExpressAddressToEcomAddress/cashierExpressAddressToEcomAddress';
import {CartApi} from '@wix/wixstores-client-storefront-sdk/dist/src/cart/cartApi/CartApi';
import {VolatileCartService} from '../services/VolatileCartService';
import {PaymentBreakdown} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {ShippingError} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';

export class ProductPageStore {
  private media: IMediaItem[] = [];
  private product: IProductDTO;
  private readonly fedopsLogger;
  private readonly productService: ProductService;
  private translations: TranslationDictionary;
  public userInputs = userInputsFactory() as UserInput;
  private multilingualService: MultilingualService;
  private sectionUrl: string;
  private isStartReported: boolean = false;
  private readonly navigationContext: IStoreFrontNavigationContext;
  private translationsPromise: Promise<any>;
  private readonly publicData: IControllerConfig['publicData'];
  private mergedPublicData: {[p: string]: any};
  private isMembersInstalled: boolean;
  private productAddedToWishlist: boolean;
  private directPurchaseService: DirectPurchaseService;
  private subscriptionService: SubscriptionService;
  private readonly currentPath: string[];
  private cashierExpressCheckoutWidgetProps: DeepPartial<WidgetProps>;
  private readonly handleCashierOnClickResult: ResultProp<boolean>;
  private countryCodes: GetProductBySlugQuery['localeData']['countries'];
  private readonly cartApi: CartApi;
  private volatileCartServiceForCashierExpress: VolatileCartService;

  constructor(
    public styleParams: IProductPageStyleParams,
    public origPublicData: IProductPageControllerConfig['publicData'],
    private readonly setProps: Function,
    private readonly siteStore: SiteStore,
    private readonly externalId: string,
    private readonly reportError: (e) => any
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: StoresWidgetID.PRODUCT_PAGE,
      fedopsAppName: this.isQuickView ? QUICK_VIEW_APP_NAME : PRODUCT_PAGE_APP_NAME,
    });
    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
      this.isStartReported = true;
    }
    this.productService = new ProductService(siteStore);
    this.navigationContext = this.getNavigationContext();
    this.publicData = _.cloneDeep(this.origPublicData);
    this.currentPath = this.siteStore.location.path;
    this.cartApi = new CartApi(this.siteStore.httpClient);

    //eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.siteStore.location.onChange(() => {
      return this.setInitialState().catch(this.reportError);
    });
  }

  public async setInitialState(): Promise<void> {
    this.sectionUrl = (await this.siteStore.getSectionUrl(PageMap.PRODUCT)).url;

    this.fedopsLogger.appLoadingPhaseStart('startFetching');
    const isMembersInstalledPromise = this.siteStore.siteApis.isAppSectionInstalled({
      appDefinitionId: APP_DEFINITION_ID,
      sectionId: PageMap.ORDER_HISTORY,
    });

    const [translations, {product, appSettings, countryCodes}, isMembersInstalled] = await all(
      this.getProductPageTranslations(),
      this.getInitialData(),
      isMembersInstalledPromise
    ).catch(this.reportError);

    this.countryCodes = countryCodes;
    this.fedopsLogger.appLoadingPhaseStart('processData');

    if (!product) {
      if (this.siteStore.seo.isInSEO()) {
        this.siteStore.seo.setSeoStatusCode(404);
      }
      console.error('Slug is missing or invalid');
      return this.setProps({
        emptyState: true,
        ...this.defaultProps,
      });
    }
    this.productAddedToWishlist = false;
    this.isMembersInstalled = isMembersInstalled;
    this.product = product;
    this.media = product.media;
    this.translations = translations;
    this.mergedPublicData = {...this.publicData.APP, ...this.publicData.COMPONENT};
    this.multilingualService = new MultilingualService(
      this.mergedPublicData,
      appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );
    this.productService.updateOptions(this.product);
    this.setInitialUserInputs();
    this.siteStore.pubSub.publish(PubSubEvents.RELATED_PRODUCTS, [this.product.id], true);
    this.fedopsLogger.appLoadingPhaseStart('setProps');
    if (this.siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS)) {
      this.subscriptionService = new SubscriptionService(this.product.subscriptionPlans, {
        label: this.translations.PRODUCT_PAGE_ONE_TIME_PURCHASE_LABEL,
        place: this.styleParams.numbers.productPage_subscriptionPlansOneTimePurchase,
        price: this.product.formattedComparePrice || this.product.formattedPrice,
      });
    }
    this.cashierExpressCheckoutWidgetProps = {
      meta: {
        appDefId: APP_DEFINITION_ID,
        appInstanceId: this.siteStore.storeId,
        siteId: this.siteStore.msid,
        visitorId: this.siteStore.uuid as string,
      },
      currency: this.siteStore.currency,
      locale: this.siteStore.locale,
    };
    await this.setInitialProps();
    this.trackViewContent();
    const setPageMetaDataPromise = this.setPageMetaData();

    if (this.siteStore.experiments.enabled('specs.stores.awaitRenderSeoTags')) {
      await setPageMetaDataPromise;
    }

    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  private reportBIOnAppLoaded() {
    const type = this.getImageResizeValue();
    const eventData: exposureEventForTestsParams = {
      isMobileFriendly: this.siteStore.isMobileFriendly,
      testName: this.isQuickView ? BiExposureTestName.QUICK_VIEW : BiExposureTestName.PRODUCT_PAGE,
      is_eligible: true,
      type,
    };

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.biLogger.exposureEventForTests(eventData);
  }

  private reportToWiXCounters() {
    return updateWixCounters(this.siteStore, this.product.id, this.siteStore.uuid);
  }

  public onAppLoaded = () => {
    /* istanbul ignore else: todo: test */
    if (!isWorker() || (this.siteStore.isInteractive() && this.isStartReported)) {
      if (this.siteStore.isSiteMode()) {
        this.reportBIOnAppLoaded();
        this.product && this.reportToWiXCounters();
      }
      this.fedopsLogger.appLoaded(this.getFedopsCustomParams());
      this.isStartReported = false;
      if (!this.product) {
        throw new Error('Slug is missing or invalid');
      }
    }
  };

  private getFedopsCustomParams() {
    const customParams: IFedopsCustomParamsFull = {
      product_page_layout: LayoutNames[this.styleParams.numbers.productPage_layoutId] as Layout,
      product_guid: this.product?.id,
      product_type: this.product?.productType as ProductType,
      product_page_images: this.styleParams.fonts.productPage_galleryNavigationType.value,
      is_stretch_to_full: this.styleParams.booleans.full_width,
      image_resize: this.getImageResizeValue(),
      add_to_cart_action:
        //eslint-disable-next-line no-nested-ternary
        this.getAddToCartAction() === AddToCartActionOption.MINI_CART
          ? 'mini-cart'
          : this.getAddToCartAction() === AddToCartActionOption.CART
          ? 'cart'
          : 'none',
      has_wishlist: this.styleParams.booleans.productPage_wishlistEnabled,
      has_buy_now_button: this.styleParams.booleans.productPage_buyNowButtonEnabled,
      has_add_to_cart_button: this.styleParams.booleans.productPage_productAction,
      social_media_bar: this.styleParams.booleans.productPage_socialNetworks,
      store_id: this.siteStore.storeId,
    };

    if (this.siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS)) {
      customParams.has_subscribe_now_button = this.subscriptionService.shouldShowSubscriptionPlans;
    }

    if (this.siteStore.experiments.enabled(SPECS.SELLING_IN_UNITS_SF)) {
      customParams.show_unit_price = !!this.product.pricePerUnitData;
    }

    return {customParams: JSON.stringify(customParams)};
  }

  private getImageResizeValue() {
    return this.styleParams.numbers.productPage_galleryImageMode === ImageModeValues.CROP
      ? ImageModeType.CROP
      : ImageModeType.FIT;
  }

  private updatePublicData(newPublicData: IProductPageControllerConfig['publicData']) {
    Object.keys(newPublicData.APP || {}).forEach((key) => {
      this.mergedPublicData[key] = newPublicData.APP[key];
    });
  }

  public updateState(
    newStyleParams: IProductPageStyleParams,
    newPublicData: IProductPageControllerConfig['publicData'] & {appSettings?: any}
  ): void {
    this.updatePublicData(newPublicData);
    this.styleParams = newStyleParams;
    this.multilingualService.setWidgetSettings(newPublicData.appSettings);
    this.setProps({
      ...this.getChangeableSettingsProps(),
      texts: this.getTexts(),
    });
  }

  private getTexts() {
    const componentAddToCartTextKey = Object.keys(this.multilingualService.getAll() || {}).find((key) =>
      COMPONENTS_ADD_TO_CART_TEXT_KEYS.includes(key)
    );

    if (componentAddToCartTextKey) {
      MULTILINGUAL_TO_TRANSLATIONS_MAP.ADD_TO_CART_BUTTON = componentAddToCartTextKey;
    }

    const componentOutOfStockTextKey = Object.keys(this.multilingualService.getAll() || {}).find((key) =>
      COMPONENTS_OUT_OF_STOCK_TEXT_KEYS.includes(key)
    );

    if (componentOutOfStockTextKey) {
      MULTILINGUAL_TO_TRANSLATIONS_MAP.PRODUCT_OUT_OF_STOCK_BUTTON = componentOutOfStockTextKey;
    }

    return Object.keys(MULTILINGUAL_TO_TRANSLATIONS_MAP).reduce(
      (acc, translationKey) => {
        const multiligualKey = MULTILINGUAL_TO_TRANSLATIONS_MAP[translationKey];
        const override = this.multilingualService.get(multiligualKey);
        if (override) {
          acc[translationKey] = override;
        }
        return acc;
      },
      {...this.translations}
    );
  }

  private getChangeableSettingsProps() {
    const changeableObject: any = {
      layoutId: this.layoutId,
      shouldShowAddToCartButton: this.styleParams.booleans.productPage_productAction,
      shouldShowBuyNowButton: this.styleParams.booleans.productPage_buyNowButtonEnabled,
      shouldShowWishlistButton: this.styleParams.booleans.productPage_wishlistEnabled && this.isMembersInstalled,
      withModalGallery: this.withModalGallery,
    };

    if (this.siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS)) {
      this.subscriptionService.setOneTimePurchasePlace(
        this.styleParams.numbers.productPage_subscriptionPlansOneTimePurchase
      );
      changeableObject.subscriptionPlans = this.subscriptionService.getSubscriptionPlans();
    }

    return changeableObject;
  }

  private async setInitialProps() {
    const props: IPropsInjectedByViewerScript = {
      ...this.defaultProps,
      ...this.getChangeableSettingsProps(),
      addedToCartStatus: ActionStatus.IDLE,
      biLogger: this.biLogger.bind(this),
      closeWixModal: this.closeWixModal.bind(this),
      cashierExpressCheckoutWidgetProps: this.cashierExpressCheckoutWidgetProps,
      fetchPaymentBreakdownForCashierAddress: this.fetchPaymentBreakdownForCashierAddress,
      emptyState: false,
      errorPlacement:
        this.siteStore.isMobile() || this.isQuickView ? ErrorTooltipPlacement.Bottom : ErrorTooltipPlacement.Left,
      errors: {},
      handleAddToCart: this.handleAddToCart,
      handleBuyNow: this.handleBuyNow,
      handleCashierOnClick: this.handleCashierOnClick,
      handleCashierOnClickResult: this.handleCashierOnClickResult,
      handleSubscribe: this.handleSubscribe,
      handleUserInput: this.handleUserInput,
      handleWishlistButtonClick: this.handleWishlistButtonClick,
      hasMultipleMedia: this.product.media.length > 1,
      hideNavigationUrls: !this.siteStore.isSiteMode(),
      infoSection: this.infoSection,
      isDesktop: this.siteStore.isDesktop(),
      isEditorMode: this.siteStore.isEditorMode(),
      isMobile: this.siteStore.isMobile(),
      isProductSubmitted: false,
      isRTL: this.siteStore.isRTL(),
      isSEO: this.siteStore.seo.isInSEO(),
      isSSR: this.siteStore.isSSR(),
      isQuickView: this.isQuickView,
      modalState: ModalState.CLOSE,
      navigate: this.navigate.bind(this),
      notifyProduct: this.notifyProductLoaded,
      pagePath: await this.pagePath(),
      pagination: this.getPrevNextProducts(),
      product: this.product,
      productUrl: this.productUrl,
      productWasAddedToWishlist: this.productAddedToWishlist,
      quantityRange: this.quantityRange,
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid},
      resetAddedToCartStatus: this.resetAddedToCartStatus,
      resetWishlistStatus: this.resetWishlistStatus,
      selectedVariant: this.productService.options.selectedVariant,
      shouldFocusAddToCartButton: false,
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      siteUrl: this.siteStore.location.baseUrl,
      socialSharing: this.socialSharing,
      texts: this.getTexts(),
      userInputErrors: userInputsFactory() as UserInputErrors,
      userInputs: this.userInputs,
      validate: this.validate,
      wishlistActionStatus: ActionStatus.IDLE,
    };

    if (this.siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS)) {
      props.subscriptionPlans = this.subscriptionService.getSubscriptionPlans();
      props.shouldShowSubscribeButton = this.subscriptionService.shouldShowSubscribeButton(this.userInputs);
      props.shouldShowSubscriptionPlans = this.subscriptionService.shouldShowSubscriptionPlans;
    }

    this.setProps(props);
  }

  private get defaultProps() {
    return {
      onAppLoaded: this.onAppLoaded,
      experiments: {
        jpgExperiment: this.siteStore.experiments.enabled('specs.stores.SSRJpgImageForPng'),
        forceFullHeight: this.siteStore.experiments.enabled('specs.stores.ForceFullHeight'),
        isSubscriptionPlansEnabled: this.siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS),
        isSellingInUnitsEnabled: this.siteStore.experiments.enabled(SPECS.SELLING_IN_UNITS_SF),
        cashierExpressWidget: this.siteStore.experiments.enabled(SPECS.CASHIER_EXPRESS_IN_PRODUCT_PAGE),
      },
      isResponsive: this.isResponsive,
      isInteractive: this.siteStore.isInteractive(),
      cssBaseUrl: this.siteStore.baseUrls.productPageBaseUrl,
    } as Partial<IPropsInjectedByViewerScript>;
  }

  private get isResponsive(): boolean {
    return this.styleParams.booleans.responsive === true;
  }

  private get withModalGallery() {
    const hasMedia = this.product.media.length > 0;
    const isViewer = !this.siteStore.isPreviewMode() && !this.siteStore.isEditorMode();
    const shouldZoom = this.styleParams.booleans.productPage_galleryZoom === true;

    const withPublicModal = ((this.isResponsive && shouldZoom) || this.siteStore.isMobile()) && hasMedia && isViewer;
    const withExperimentalModal =
      this.layoutId === LayoutId.Classic &&
      this.siteStore.experiments.enabled(SPECS.NEW_ZOOM_IN_CLASSIC_LAYOUT) &&
      shouldZoom;

    return withPublicModal || withExperimentalModal;
  }

  private setInitialUserInputs() {
    const textFieldsLength = this.product.customTextFields ? this.product.customTextFields.length : 0;

    const selection = this.product.options.reduce((acc, option, i) => {
      const shouldPreselect = option.selections.length > 1;
      acc[i] = shouldPreselect ? null : option.selections[0];
      return acc;
    }, []);

    this.userInputs = {
      selection,
      text: Array(textFieldsLength).fill(null),
      quantity: [1],
      subscriptionPlan: [],
    };

    this.updateSelections();
  }

  private readonly socialSharing = {
    onClick: (data: SocialSharingEvent) => {
      const socialSharingEventWithProduct: socialButtonsParams = {...data, productId: this.product.id};
      return this.siteStore.biLogger.socialButtons(socialSharingEventWithProduct);
    },
  };

  private readonly infoSection = {
    onActive: (BIEvent: InfoSectionEvent) => {
      const infoSectionEventWithProduct: clickOnProductDetailsSfParams = {...BIEvent, productId: this.product.id};
      return this.siteStore.biLogger.clickOnProductDetailsSf(infoSectionEventWithProduct);
    },
  };

  private getProductPageTranslations(): Promise<TranslationDictionary> {
    //eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (this.translationsPromise) {
      return this.translationsPromise;
    }
    this.translationsPromise = getTranslations(
      translationPath(this.siteStore.baseUrls.productPageBaseUrl, this.siteStore.locale)
    );
    return this.translationsPromise;
  }

  private readonly navigate = (productUrlPart: string, closeWixModal: boolean = false): void => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.navigate({
      sectionId: PageMap.PRODUCT,
      queryParams: undefined,
      state: productUrlPart,
    });

    if (closeWixModal) {
      //eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.siteStore.biLogger.clickOnProductBoxSf({
        productId: this.product.id,
        hasRibbon: !!this.product.ribbon,
        hasOptions: !!this.product.options.length,
        index: 0,
        productType: this.product.productType,
        origin: 'quick-view',
      });
      this.closeWixModal();
    }
  };

  private closeWixModal() {
    this.siteStore.windowApis.closeWindow();
  }

  private readonly handleUserInput = (inputType: UserInputType, data: UserInputData = null, index: number) => {
    this.userInputs[inputType][index] = data;
    this.updateSelections();
    this.nextProps();
  };

  private readonly updateSelections = () => {
    this.productService.options.updateSelections(this.product, this.userInputs.selection);
    this.filterMedia();
  };

  private readonly nextProps = (additionalProps = {} as Partial<IPropsInjectedByViewerScript>) => {
    const nextProps: Partial<IPropsInjectedByViewerScript> = {
      quantityRange: this.quantityRange,
      product: this.product,
      selectedVariant: this.productService.options.selectedVariant,
      userInputs: this.userInputs,
      ...additionalProps,
    };

    if (this.siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS)) {
      nextProps.shouldShowSubscribeButton = this.subscriptionService.shouldShowSubscribeButton(this.userInputs);
      nextProps.subscriptionPlans = this.subscriptionService.getSubscriptionPlans();
    }

    this.setProps(nextProps);
  };

  public readonly validate = (): void => {
    this.nextProps({
      userInputErrors: this.productService.validate(this.userInputs),
    });
  };

  private readonly createDirectPurchaseService = () => {
    this.directPurchaseService = new DirectPurchaseService(
      this.siteStore,
      this.reportError,
      //eslint-disable-next-line @typescript-eslint/no-misused-promises
      (buttonType: BiButtonActionType) => this.reportButtonAction(buttonType),
      this.nextProps,
      this.fedopsLogger,
      () => this.trackEventForBuyNowAndSubscribe(),
      () => this.handleQuickViewClose()
    );
  };

  private readonly validateProductSubmission = (): boolean => {
    const validationObject = this.isInvalid(this.userInputs);

    if (validationObject.isInvalid) {
      this.nextProps({
        isProductSubmitted: true,
        userInputErrors: validationObject.validations,
      });
      return false;
    } else {
      return true;
    }
  };

  private readonly handleQuickViewClose = () => {
    if (this.isQuickView) {
      this.siteStore.windowApis.closeWindow();
    }
  };

  private readonly handleCashierOnClick = async () => {
    // istanbul ignore else: todo(eran): in progress
    if (!this.directPurchaseService) {
      this.createDirectPurchaseService();
    }

    //todo(eran): this await is an hack until I fix this: https://wix.slack.com/archives/C68TQQSNM/p1595256383021500
    //eslint-disable-next-line @typescript-eslint/await-thenable
    const shouldProceed = await this.validateProductSubmission();
    if (!shouldProceed) {
      this.nextProps({handleCashierOnClickResult: withChangeListener(false)});
      return;
    }

    const canCheckout = await this.directPurchaseService.handleCashierOnClick(this.product);
    this.nextProps({handleCashierOnClickResult: withChangeListener(canCheckout)});

    this.volatileCartServiceForCashierExpress = new VolatileCartService(this.siteStore.httpClient, this.siteStore);
    await this.volatileCartServiceForCashierExpress.getStandaloneCartId(
      this.product.id,
      this.userInputs.selection.map((selected) => selected.id),
      this.userInputs.quantity[0],
      formatCustomTextFields(this.product, this.userInputs).map((customTextField) => {
        return {title: customTextField.customText.title, value: customTextField.answer};
      })
    );
  };

  private readonly fetchPaymentBreakdownForCashierAddress = async (
    shippingAddress: ShippingContactRestricted
  ): Promise<void> => {
    const {country, subdivision, zipCode} = cashierExpressAddressToEcomAddress(shippingAddress, {}, this.countryCodes);

    await this.cartApi.setShippingAddressesForFastFlow(this.volatileCartServiceForCashierExpress.cartId, {
      country,
      subdivision,
      zipCode,
    });

    const cart = await this.volatileCartServiceForCashierExpress.getCart();
    const notEnoughInfoAboutSubdivision = cart.cartService.cart.destinationCompleteness.includes('SUBDIVISION');

    const totals = cart.cartService.cart.convertedTotals;

    //todo(eran): add shipping and tax only when needed
    const initialPaymentBreakdown: PaymentBreakdown = {
      shipping: totals.shipping.toString(),
      tax: totals.tax.toString(),
      discount: totals.discount.toString(),
      itemsTotal: totals.itemsTotal.toString(),
    };

    if (cart.cartService.cart.shippingRuleInfo.canShipToDestination || notEnoughInfoAboutSubdivision) {
      this.nextProps({
        fetchPaymentBreakdownForCashierAddressResult: withChangeListener({
          paymentAmount: cart.cartService.cart.convertedTotals.total.toString(),
          paymentBreakdown: initialPaymentBreakdown,
        }),
      });
    } else {
      this.nextProps({
        fetchPaymentBreakdownForCashierAddressResult: withChangeListener({
          error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE,
        }),
      });
    }
  };

  private readonly handleBuyNow = async (accessibilityEnabled: boolean): Promise<void> => {
    const shouldProceed = this.validateProductSubmission();
    if (!shouldProceed) {
      return;
    }

    this.fedopsLogger.interactionStarted(productPageFedopsEvent.BuyNow);

    /* istanbul ignore else: todo(ariel): test else */
    if (!this.directPurchaseService) {
      this.createDirectPurchaseService();
    }

    return this.directPurchaseService.handleBuyNow(accessibilityEnabled, this.product, this.userInputs);
  };

  private readonly handleSubscribe = async (accessibilityEnabled: boolean): Promise<void> => {
    const shouldProceed = this.validateProductSubmission();
    if (!shouldProceed) {
      return;
    }

    this.fedopsLogger.interactionStarted(productPageFedopsEvent.Subscribe);

    /* istanbul ignore else: todo(ariel): test else */
    if (!this.directPurchaseService) {
      this.createDirectPurchaseService();
    }
    return this.directPurchaseService.handleSubscribe(accessibilityEnabled, this.product, this.userInputs);
  };

  private readonly handleAddToCart = async (): Promise<any> => {
    const validationObject = this.isInvalid(this.userInputs);

    if (validationObject.isInvalid) {
      this.nextProps({
        isProductSubmitted: true,
        userInputErrors: validationObject.validations,
      });
      return;
    }

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reportButtonAction(BiButtonActionType.AddToCart);
    this.fedopsLogger.interactionStarted(productPageFedopsEvent.AddToCart);

    this.trackAddToCart();

    const eventId = this.siteStore.pubSubManager.subscribe(
      'Minicart.DidClose',
      () => {
        this.setProps({
          //random so it will never be the same value
          shouldFocusAddToCartButton: Math.random(),
        });

        this.siteStore.pubSubManager.unsubscribe('Minicart.DidClose', eventId);
      },
      true
    );
    this.setProps({addedToCartStatus: ActionStatus.SUCCESSFUL});
    return this.productService.addToCart(
      this.product,
      this.userInputs,
      this.getAddToCartAction(),
      this.biOrigin,
      this.onAddToCartSuccess
    );
  };

  private readonly onAddToCartSuccess = () => {
    this.handleQuickViewClose();
    this.fedopsLogger.interactionEnded(productPageFedopsEvent.AddToCart);
  };

  private readonly resetAddedToCartStatus = () => {
    this.setProps({addedToCartStatus: ActionStatus.IDLE});
  };

  private getHeaders() {
    return {
      Authorization: (this.siteStore.httpClient.getBaseHeaders() as any).Authorization,
    };
  }

  private readonly handleWishlistButtonClick = async () => {
    if (!this.siteStore.usersApi.currentUser.loggedIn) {
      await this.siteStore.usersApi.promptLogin({});
    }

    const wishlistActionPromise = this.productAddedToWishlist
      ? new WishlistActions(this.getHeaders()).removeProducts([this.product.id])
      : new WishlistActions(this.getHeaders()).addProducts([this.product.id]);
    this.toggleWishlistState();
    this.reportWishlistFedops(true);
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reportWishlistBI();
    await wishlistActionPromise
      .then(() => {
        this.reportWishlistFedops(false);
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.reportWishlistSuccessfulOperationBI();
      })
      .catch(() => {
        this.toggleWishlistState();
      });
  };

  private toggleWishlistState() {
    this.productAddedToWishlist = !this.productAddedToWishlist;
    this.setProps({
      productWasAddedToWishlist: this.productAddedToWishlist,
      wishlistActionStatus: ActionStatus.SUCCESSFUL,
    });
  }

  private readonly resetWishlistStatus = () => {
    this.setProps({
      wishlistActionStatus: ActionStatus.IDLE,
    });
  };

  private getAddToCartAction() {
    let addToCartAction = AddToCartActionOption.MINI_CART;
    if (this.styleParams.numbers.productPage_addToCartAction) {
      addToCartAction = this.styleParams.numbers.productPage_addToCartAction;
    } else if (!this.styleParams.booleans.productPage_openMinicart) {
      addToCartAction = AddToCartActionOption.NONE;
    }
    return addToCartAction;
  }

  private readonly notifyProductLoaded = () => {
    return this.siteStore.windowApis.trackEvent(
      'productPageLoaded' as any,
      {
        productId: this.product.id,
        name: this.product.name,
        currency: this.siteStore.currency,
        price: this.product.price,
        sku: this.product.sku,
      } as any
    );
  };

  private getAddToCartActionName(): string {
    //eslint-disable-next-line no-nested-ternary
    return this.getAddToCartAction() === AddToCartActionOption.MINI_CART && !this.productService.shouldNavigateToCart()
      ? 'mini-cart'
      : this.getAddToCartAction() === AddToCartActionOption.CART ||
        (this.productService.shouldNavigateToCart() && this.getAddToCartAction() !== AddToCartActionOption.NONE)
      ? 'cart'
      : 'none';
  }

  private reportButtonAction(buttonType: BiButtonActionType) {
    const eventData = {
      buttonType,
      appName: 'productPageApp',
      hasOptions: this.userInputs.selection.length > 0,
      productId: this.product.id,
      productType: this.product.productType as ProductType,
      origin: this.biOrigin,
      isNavigateCart: !this.styleParams.booleans.productPage_openMinicart || this.productService.shouldNavigateToCart(),
      navigationClick:
        buttonType === BiButtonActionType.BuyNow || buttonType === BiButtonActionType.Subscribe
          ? 'checkout'
          : this.getAddToCartActionName(),
      quantity: Math.round(this.selectedQuantity),
    };

    return this.siteStore.biLogger.clickOnAddToCartSf(eventData);
  }

  private reportWishlistBI() {
    const eventData = this.createWishlistBiEventData();

    return this.productAddedToWishlist
      ? this.siteStore.biLogger.clickAddToWishlistSf(eventData)
      : this.siteStore.biLogger.clickRemoveFromWishlistSf(eventData);
  }

  private reportWishlistSuccessfulOperationBI() {
    const eventData = this.createWishlistBiEventData();

    return this.productAddedToWishlist
      ? this.siteStore.biLogger.productAddedToWishlistSf(eventData)
      : this.siteStore.biLogger.productRemovedFromWishlistSf(eventData);
  }

  private createWishlistBiEventData() {
    return {
      appName: 'productPageApp',
      hasOptions: this.product.options.length > 0,
      productId: this.product.id,
      productType: this.product.productType as ProductType,
      origin: this.biOrigin,
    };
  }

  private reportWishlistFedops(isInteractionStarted: boolean) {
    const interactionName = this.productAddedToWishlist
      ? productPageFedopsEvent.AddToWishlist
      : productPageFedopsEvent.RemoveFromWishlist;
    return isInteractionStarted
      ? this.fedopsLogger.interactionStarted(interactionName)
      : this.fedopsLogger.interactionEnded(interactionName);
  }

  private trackInitiateCheckout() {
    const variant = this.productService.options.selectedVariant || this.product;

    const params = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: variant.comparePrice || variant.price,
      currency: this.siteStore.currency,
      quantity: this.selectedQuantity,
    };

    return this.siteStore.windowApis.trackEvent(TrackEventName.INITIATE_CHECKOUT, params);
  }

  private trackAddToCart() {
    const variant = this.productService.options.selectedVariant || this.product;

    const params: ITrackEventParams = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: variant.comparePrice || variant.price,
      currency: this.siteStore.currency,
      quantity: this.selectedQuantity,
      sku: this.product.sku,
      type: this.product.productType,
    };

    return this.siteStore.windowApis.trackEvent(TrackEventName.ADD_TO_CART, params);
  }

  private trackEventForBuyNowAndSubscribe() {
    this.trackAddToCart();
    this.trackInitiateCheckout();
  }

  private trackViewContent() {
    const params: ITrackEventParams = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: this.product.comparePrice || this.product.price,
      currency: this.siteStore.currency,
      type: this.product.productType,
      sku: this.product.sku,
      dimension3: this.product.isInStock ? 'in stock' : 'out of stock',
    };

    return this.siteStore.windowApis.trackEvent(TrackEventName.VIEW_CONTENT, params);
  }

  private readonly isInvalid = (userInputs: UserInput): {isInvalid: boolean; validations: UserInputErrors} => {
    const validations = this.productService.validate(userInputs);

    const isInvalid = Object.keys(validations).reduce((accValidation: boolean, currentKey: string) => {
      const flag = validations[currentKey].some((value) => value === true);
      return accValidation || flag;
    }, false);

    return {isInvalid, validations};
  };

  private filterMedia(): void {
    const filteredMedia = _.flatMap(this.userInputs.selection, (item) => item?.linkedMediaItems || []);
    this.product.media = filteredMedia.length ? (filteredMedia as any) : this.media;
  }

  private getUrlWithoutParams(url: string): string {
    const parsedUrl = parseUrl(url);
    return `${parsedUrl.protocol}://${parsedUrl.host}${parsedUrl.path}`;
  }

  private async getInitialData(): Promise<{
    product: IProductDTO;
    appSettings: IAppSettings;
    countryCodes: GetProductBySlugQuery['localeData']['countries'];
  }> {
    let product: any;
    let appSettings: IAppSettings;
    let countryCodes: GetProductBySlugQuery['localeData']['countries'];
    const currentUrl = this.getUrlWithoutParams(this.siteStore.location.url);

    if (this.siteStore.isSiteMode() && currentUrl === this.sectionUrl && this.siteStore.seo.isInSEO()) {
      return {product: null, appSettings: null, countryCodes: null};
    }

    if (
      ((this.siteStore.isEditorMode() || this.siteStore.isPreviewMode()) && this.currentPath.length > 1) ||
      (this.siteStore.isSiteMode() && currentUrl !== this.sectionUrl)
    ) {
      const data = await this.productService.getProductBySlug(this.slug, this.externalId);
      product = data.catalog.product;
      appSettings = data.appSettings;
      countryCodes = data.localeData?.countries;
    } else {
      const data = await this.productService.getDefaultProduct(this.externalId);
      product = data.catalog.products.list[0];
      appSettings = data.appSettings;
    }

    return {product, appSettings, countryCodes};
  }

  private getPrevNextProducts(): ProductPagePagination {
    let prevProduct = {} as ProductPagePaginationItem;
    let nextProduct = {} as ProductPagePaginationItem;

    const paginationMap = this.navigationContext.paginationMap;

    const getUrl = (slug: string) => {
      const prefix = `${this.sectionUrl}/${slug}`;
      return (
        prefix +
        /* istanbul ignore next */ (Object.keys(this.siteStore.location.query).length
          ? `?${queryToString(this.siteStore.location.query)}`
          : '')
      );
    };

    paginationMap.forEach((slug: string, index: number) => {
      if (slug === this.product.urlPart) {
        if (paginationMap[index - 1]) {
          prevProduct = {
            partialUrl: paginationMap[index - 1],
            fullUrl: getUrl(paginationMap[index - 1]),
          };
        }
        if (paginationMap[index + 1]) {
          nextProduct = {
            partialUrl: paginationMap[index + 1],
            fullUrl: getUrl(paginationMap[index + 1]),
          };
        }
      }
    });

    return {
      nextProduct,
      prevProduct,
    };
  }

  private get slug() {
    if (
      this.siteStore.experiments.enabled(SPECS.USE_LIGHTBOXES) &&
      this.siteStore.windowApis.lightbox.getContext()?.productSlug
    ) {
      return this.siteStore.windowApis.lightbox.getContext().productSlug;
    }
    const dirtySlug = decodeURIComponent(this.siteStore.location.path[this.siteStore.location.path.length - 1]);
    return dirtySlug.split('?')[0];
  }

  private get quantityRange(): ProdudctQunatityRange {
    const qunatities = QuantityCalculator.getQuantitiesRange(
      this.product,
      this.userInputs.selection as IProductOptionSelection[]
    );
    return {max: qunatities[qunatities.length - 1], min: qunatities[0]};
  }

  private get selectedQuantity(): number {
    return this.userInputs.quantity[0];
  }

  private readonly setPageMetaData = (): Promise<any> => {
    if (!this.siteStore.isSiteMode()) {
      return;
    }

    let seoData;

    try {
      seoData = JSON.parse((this.product as any).seoJson);
    } catch {
      //
    }

    const productWithPageUrl = {...this.product, pageUrl: this.productUrl};
    const itemData: ProductPageItemData = {
      product: new SeoProductBuilder(productWithPageUrl as any, {
        productPageBaseUrl: this.sectionUrl,
      }).get(),
      legacySeoData: {
        title: this.product.seoTitle,
        description: this.product.seoDescription,
      },
    };

    //eslint-disable-next-line no-use-of-empty-return-value
    return (this.siteStore.seo.renderSEOTags({
      itemType: 'STORES_PRODUCT',
      itemData,
      seoData,
    }) as any) as Promise<any>;
  };

  private readonly pagePath = async (): Promise<StructurePage[]> => {
    if (this.siteStore.isSSR()) {
      return [];
    }

    const siteStructure = await this.siteStore.siteApis.getSiteStructure();
    const path = siteStructure.pages.filter((p) => p.isHomePage);

    const navigatedFromPageId = this.navigationContext.pageId;
    if (navigatedFromPageId) {
      const page = siteStructure.pages.find((p) => {
        const notHomepage = !p.isHomePage;
        const notSelf = p.id !== this.siteStore.siteApis.currentPage.id;
        const matchRef = p.id === navigatedFromPageId;
        return notHomepage && notSelf && matchRef;
      });
      if (page) {
        page.name = capitalizeFirstLetters(page.name);
        page.url = `${this.siteStore.location.baseUrl}${page.url}`;
        path.push(page);
      }
    }

    path.push({name: this.product.name, url: null, isHomePage: false, id: null});
    path[0].name = this.translations.BREADCRUMBS_HOME;
    path[0].url = this.siteStore.location.baseUrl;
    return path;
  };

  private getNavigationContext(): IStoreFrontNavigationContext {
    let context: IStoreFrontNavigationContext;
    try {
      context = JSON.parse(this.siteStore.storage.local.getItem(STORAGE_PAGINATION_KEY));
    } catch {
      //
    }
    return context || {pageId: undefined, paginationMap: []};
  }

  private get productUrl(): string {
    return `${this.sectionUrl}/${this.slug}`;
  }

  private get isQuickView(): boolean {
    const byLayout = this.styleParams.numbers.productPage_layoutId === LayoutId.QuickView;
    //eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const byQuery = typeof location !== 'undefined' && !!location.search.match(/layout=quickview/);
    return byLayout || byQuery;
  }

  /* istanbul ignore next */
  public biLogger(event: BILoggerEvents, params: Record<string, any> = {}): void {
    (this.siteStore.biLogger[event] as Function).call(this.siteStore.biLogger, params);
  }

  private get biOrigin(): string {
    return this.isQuickView ? Origin.QUICK_VIEW : Origin.PRODUCT_PAGE;
  }

  private get layoutId(): LayoutId {
    return this.isQuickView ? LayoutId.QuickView : this.styleParams.numbers.productPage_layoutId;
  }
}
